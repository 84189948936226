import React from 'react'
import Navbar from './nav/Navbar'

export default function Header() {
  return (
    <header>
        <Navbar />
    </header>
  )
}
