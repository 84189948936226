import { Link } from 'gatsby'
import React from 'react'
import './Navbar.scss';

export default function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <div className="site-title">
            <Link to="/">AGoodId</Link>
          </div>
        </div>
    </nav>
  )
}
