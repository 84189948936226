import React from "react"
import "./Layout.scss"
import Header from "./Header"

export default function Layout({ children, className }) {
  return (
    <div className={`wrapper${className ? ' ' + className : ''}`}>
      <Header />
      <div className="container-fluid page-wrapper">
        {children}
      </div>
    </div>
  )
}
