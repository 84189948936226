import React from 'react';
import "bootstrap/scss/bootstrap.scss"
import "@wordpress/block-library/build-style/style.css";
import './global.scss';
import Layout from './src/components/Layout';

// Logs when the client route changes
const onPreRouteUpdate = ({ location, prevLocation }) => {
  console.log("new pathname", location.pathname)
  console.log("old pathname", prevLocation ? prevLocation.pathname : null)
  console.log(window.location.pathname)
}

// Wraps every page in a component
const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export { onPreRouteUpdate };